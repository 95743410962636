import React from 'react'
import Lightbox from 'react-image-lightbox';

import Img00 from "../images/rooms/hallway-1.jpg"
import Img01 from "../images/rooms/parlor-1.jpg"
import Img02 from "../images/rooms/family-room-1.jpg"
import Img03 from "../images/rooms/kitchen-sitting-area-1.jpg"
import Img04 from "../images/rooms/parlor-2.jpg"
import Img05 from "../images/rooms/library-1.jpg"
import Img06 from "../images/rooms/library-2.jpg"
import Img07 from "../images/rooms/bathroom2-1.jpg"
import Img08 from "../images/rooms/kitchen-sitting-area-2.jpg"
import Img09 from "../images/rooms/bedroom2-2.jpg"
import Img10 from "../images/rooms/bedroom5-2.jpg"
import Img11 from "../images/rooms/bedroom1-1.jpg"
import Img12 from "../images/rooms/bedroom5-3.jpg"
import Img13 from "../images/rooms/bedroom3-2.jpg"

const images = [
    Img00,
    Img01,
    Img02,
    Img03,
    Img04,
    Img05,
    Img06,
    Img07,
    Img08,
    Img09,
    Img10,
    Img11,
    Img12,
    Img13
 ];

export default class PhotoLightboxGrounds extends React.Component {

    
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
        <div>
            <div class="row">
                <div class="col-sm-4">
                    <img src={Img00} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:0 })} />
                </div>
                <div class="col-sm-8">
                    <div class="row">
                        <div class="col-sm-6">
                            <img src={Img01} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:1 })} />
                        </div>
                        <div class="col-sm-6">
                            <img src={Img02} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:2 })} />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <img src={Img03} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:3 })} />
                        </div>
                        <div class="col-sm-6">
                            <img src={Img04} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:4 })} />
                        </div>
                    </div>                    
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <img src={Img05} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:5 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img06} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:6 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img07} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:7 })} />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <img src={Img08} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:8 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img09} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:9 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img10} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:10 })} />
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm-4">
                    <img src={Img11} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:11 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img12} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:12 })} />
                </div>
                <div class="col-sm-4">
                    <img src={Img13} class="img-fluid img-thumbnail" onClick={() => this.setState({ isOpen: true, photoIndex:13 })} />
                </div>
            </div>
            
           {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )}
        </div>
    )
  }
}